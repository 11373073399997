import {customElement, bindable, inject} from 'aurelia-framework';

@customElement("sio-tab-table")
export class TabContentTable {
    @bindable object;
    @bindable tab;

    additionalFetchConditions(tab, object) {
        const refField = tab.preset.properties.refField;
        const presetConditions = tab.preset.conditions;

        let conditions = {
            [refField]: { $eq: { id: object.id, modelId: object.modelId } }
        };

        if (presetConditions && Object.keys(presetConditions).length > 0) {
            conditions = { $and: [conditions, presetConditions] };
        }

        return conditions;
    }

}
